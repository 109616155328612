.date-picker {
  position: relative;
}

.date-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(218, 218, 218, 0.7);
}

.calendar {
  z-index: 150;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 6px;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #0056b3;
}

.current-month {
  font-weight: bold;
  font-size: 18px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day-button {
  /* background-color: #f8f9fa; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  /* padding: 2px 5px 2px 5px; */
  text-align: center;
  width: 25px;
  cursor: pointer;
}

.day-button:hover {
  background-color: #007bff;
  color: white;
}

.days-of-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px; /* เพิ่มระยะห่างจาก header */
}

.day-of-week {
  text-align: center;
  font-weight: bold;
  color: #007bff; /* เปลี่ยนสีตัวหนังสือวันในสัปดาห์ */
}

.empty-day {
  width: 40px; /* ขนาดที่เหมาะสม */
  height: 40px; /* ขนาดที่เหมาะสม */
}

.selected {
  background-color: #007bff; /* สีพื้นหลังเมื่อถูกเลือก */
  color: white; /* สีตัวหนังสือเมื่อถูกเลือก */
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.date-input {
  align-items: center;
  /* text-align: center; */
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px; /* เพื่อเว้นพื้นที่สำหรับไอคอน */
  width: 145px;
  border-radius: 8px;
}

.calendar-icon {
  position: absolute;
  right: 10px; /* ระยะห่างจากขอบด้านขวา */
  top: 50%; /* แนวตั้งกลาง */
  transform: translateY(-50%); /* ให้ตรงกลาง */
  cursor: pointer; /* เปลี่ยน cursor เป็น pointer */
}

.icon {
  width: 20px; /* ขนาดไอคอน */
  height: 20px;
  color: #637381; /* สีไอคอน */
}

.prev-month-day {
  color: #ccc; /* สีจางลงเพื่อให้ดูว่าเป็นวันที่จากเดือนก่อนหน้า */
  pointer-events: none; /* ไม่ให้คลิกได้ */
}

.day-button.selected {
  background-color: #007bff;
  color: white;
}

.next-month-day {
  color: #ccc; /* สีจางลงเพื่อให้ดูว่าเป็นวันที่ของเดือนถัดไป */
  pointer-events: none; /* ไม่ให้คลิกได้ */
  text-align: center;
}

.prev-month-day {
  color: #ccc; /* สีจางลงเพื่อให้ดูว่าเป็นวันที่ของเดือนก่อนหน้า */
  pointer-events: none;
  text-align: center;
}

.day-button.selected {
  background-color: #007bff;
  color: white;
}

.month-picker {
  position: relative;
  display: inline-block;
}
