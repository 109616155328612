.month-picker {
  position: relative;
  display: inline-block;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.date-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.date-input {
  align-items: center;
  /* text-align: center; */
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px; /* เพื่อเว้นพื้นที่สำหรับไอคอน */
  width: 145px;
  border-radius: 8px;
}

.calendar-icon {
  position: absolute;
  right: 10px; /* ระยะห่างจากขอบด้านขวา */
  top: 50%; /* แนวตั้งกลาง */
  transform: translateY(-50%); /* ให้ตรงกลาง */
  cursor: pointer; /* เปลี่ยน cursor เป็น pointer */
}

.icon {
  width: 20px; /* ขนาดไอคอน */
  height: 20px;
  color: #637381; /* สีไอคอน */
}

.month-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  margin-top: 5px;
  z-index: 150;
}

.year-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.months-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.month-item {
  text-align: center;
  padding: 2px 8px;
  cursor: pointer;
  border-radius: 4px;
  /* background-color: #f0f0f0; */
}

.month-item.selected {
  background-color: #007bff;
  color: white;
}

.month-item:hover {
  background-color: #d4d4d4;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.clear-button,
.current-button {
  font-size: 12px;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.clear-button:hover,
.current-button:hover {
  text-decoration: none;
}

.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 6px;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #0056b3;
}
