@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Loader css */
.loader {
  font-size: 16px;
  position: relative;
  width: 4em;
  height: 1em;
  margin: 175px auto;
}
.dot {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.5em;
  background: #333;
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background: rgb(30 64 175 / 0.95);
}
.dot1,
.dot2 {
  left: 0;
}
.dot3 {
  left: 1.5em;
}
.dot4 {
  left: 3em;
}
@keyframes reveal {
  from {
    transform: scale(0.001);
  }
  to {
    transform: scale(1);
  }
}
@keyframes slide {
  to {
    transform: translateX(1.5em);
  }
}
.dot1 {
  animation-name: reveal;
}
.dot2,
.dot3 {
  animation-name: slide;
}
.dot4 {
  animation-name: reveal;
  animation-direction: reverse;
}
/* End - Loader css */

.tab-red {
  color: #ff0000;
  border-color: #ff0000;
}
.tab-yellow {
  color: #f4f000;
  border-color: #f4f000;
}
.tab-pink {
  color: #ff5de4;
  border-color: #ff5de4;
}
.tab-green {
  color: #007f1b;
  border-color: #007f1b;
}
.tab-orange {
  color: #ff9d00;
  border-color: #ff9d00;
}
.tab-blue {
  color: #46ccfd;
  border-color: #46ccfd;
}
.tab-purple {
  color: #a200ff;
  border-color: #a200ff;
}
.tab-deep-blue {
  color: #346ded;
  border-color: #346ded;
}
