/* Custom table header styling */
.DataTable__header {
  background-color: #3b82f6; /* Custom header background color */
  color: white; /* Header text color */
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

/* Custom head cell styles */
.DataTable__head-cell {
  padding: 10px;
  text-align: center;
  border-right: 1px solid #ddd; /* Add borders between cells */
}

/* Custom row styling */
.DataTable__row {
  min-height: 60px; /* Set custom row height */
  padding: 10px;
  text-align: center;
}

/* Hover effect for rows */
.DataTable__row:hover {
  background-color: #f1f5f9; /* Change the row color on hover */
}

/* Custom cell styles */
.DataTable__cell {
  padding: 12px;
  text-align: center;
}

/* Pagination style */
.DataTable__pagination {
  background-color: #f4f4f9;
  border-top: 1px solid #e2e8f0;
}

.DataTable__pagination-button {
  color: #3b82f6;
}

/* Adjust pagination button styling */
.DataTable__pagination button:disabled {
  opacity: 0.5;
  color: gray;
}

/* Dropdown container */
.myDropdown {
  /* width: 150px; */
  /* font-family: Arial, sans-serif; */
  /* border: 1px solid #ccc;
  border-radius: 15px;
  padding: 5px; */
  color: #ddd;
}
.myDropdown:hover {
  color: #000 !important;
}

/* Custom arrow */
.myDropdownArrow {
  /* border-top: 6px solid #3758f9;  */
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

/* Dropdown menu */
.myDropdownMenu {
  border-radius: 8px;
  color: #ddd;
}

/* Dropdown item hover effect */
.myDropdownMenu .Dropdown-option:hover {
  color: #000;
}

/* Selected option styling */
.myDropdownSelected {
  font-weight: bold;
}
.myDropdownControl {
  border-radius: 8px;
  padding: 5px 15px;
  width: 155px;
  height: 35px;
  color: #ddd;
  border-color: #ddd;
  box-shadow: 0px 2px 4px rgba(218, 218, 218, 0.7);
}

[data-tag="allowRowEvents"] {
  width: 100% !important;
}

.sc-cHqXqK {
  width: 100% !important;
}

.dQcPXM {
  flex-grow: 1;
  flex-shrink: inherit !important;
  /* flex-basis: auto !important; */
  /* max-width: 100% !important; */
  /* min-width: 100px; */
}

.rdt_TableHeadRow {
  border-radius: 15px 15px 0px 0px !important;
}

.bOmZtP div:first-child {
  /* white-space: normal !important; */
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-datepicker-popper {
  z-index: 150 !important;
}

input {
  outline: -webkit-focus-ring-color auto 0px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem 0;
  box-sizing: content-box;
  right: 0px;
}

.react-datepicker__view-calendar-icon input {
  padding: 5px 0px 5px 12px !important;
}

.body-page {
  min-height: 60vh;
}
